<template>
  <div name="reports">
    <header class="inner-head">
      <div class="flex">
        <h2 class="inner-head-title">Reporting</h2>

        <ul class="subnav">
               <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
            <button
              v-bind:class="checkTab(0)"
              v-on:click="showCharts()"
              class="focus:outline-none"
            >
              Overview
            </button>
          </li>
             <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
            <button
              v-bind:class="checkTab(1)"
              v-on:click="showAssessment()"
              class="focus:outline-none"
            >
              Assessment Grades
            </button>
          </li>
            <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
            <button
              v-bind:class="checkTab(2)"
              v-on:click="showActivities()"
              class="focus:outline-none"
            >
              Extra Curricular
            </button>
          </li>
           <li class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
            <button
              v-bind:class="checkTab(3)"
              v-on:click="showEvents()"
              class=" focus:outline-none"
            >
              Events
            </button>
          </li>
        </ul>
      </div>
    </header>

    <div class="filter-bar">
      <ul class="actions">
        <li class="opacity-75">Filter by:</li>
        <li>
          <button
            type="button"
            class="filter-button focus:outline-none hover:opacity-75"
            title="Filter"
            @click="setFilter()"
          >
           <i class="fas fa-user-friends"
              v-bind:class="showFilter ? 'bg-action-blue text-white' : 'bg-gray-400'"></i> Pupils
          </button>
        </li>
      </ul>
    </div>

    <filterSection
      class=""
      :classes="classesArray"
      :fullView="true"
      :filterOpen="showFilter"
      v-if="showFilter"
      @classUpdated="updateFilteredClass"
      @yearsUpdated="updateFilteredYears"
      @typesUpdated="updateFilteredTypes"
    >
    </filterSection>

    <section class="bg-white py-4 pl-6 hidden">
      <ul class="flex">
        <li class="mr-3 cursor-pointer">
          <button
            v-bind:class="checkTab(0)"
            v-on:click="showCharts()"
            class="inline-block border rounded py-1 px-3"
          >
            Overview
          </button>
        </li>
        <li class="mr-3 cursor-pointer">
          <button
            v-bind:class="checkTab(1)"
            v-on:click="showAssessment()"
            class="inline-block border rounded py-1 px-3"
          >
            Assessment Grades
          </button>
        </li>
        <li class="mr-3 cursor-pointer">
          <button
            v-bind:class="checkTab(2)"
            v-on:click="showActivities()"
            class="inline-block border rounded py-1 px-3"
          >
            Extra Curricular
          </button>
        </li>
        <li class="mr-3 cursor-pointer">
          <button
            v-bind:class="checkTab(3)"
            v-on:click="showEvents()"
            class="inline-block border rounded py-1 px-3"
          >
            Events
          </button>
        </li>
      </ul>
    </section>

    <div v-if="errored" class="ml-10 mt-4 font-bold text-red-400">
      <p>
        We are not able to retrieve this information at the moment, please try
        back later
      </p>
    </div>

    <div id="content-area" class="mx-4" v-else>
      <div
        style="width: 100%; text-align: center"
        class="spin-holder"
        v-if="loading"
      >
        <i class="spinner fa fa-spinner fa-spin"></i>
        <p>Loading Reports</p>
      </div>

      <div id="printable" class="" v-else>
        <div v-if="isSelectedContent(0)">
          <assessmentChart
            :assessmentData="orderedAssessments"
          ></assessmentChart>
        </div>

        <div v-show="isSelectedContent(1)">
          <assessmentExtract
            :assessments="orderedAssessments"
          ></assessmentExtract>
        </div>

        <div v-if="isSelectedContent(2)">
          <activityChart
            :activities="activitiesFiltered"
            :pupilCount="pupilArrayCount"
          ></activityChart>
          <activityExtract
            :activities="activitiesFiltered"
            :years="years"
            :types="types"
          ></activityExtract>
        </div>

        <div v-if="isSelectedContent(3)">
          <eventChart
            :events="eventsFiltered"
            :pupilCount="pupilArrayCount"
          ></eventChart>
          <eventExtract
            :events="eventsFiltered"
            :years="years"
            :types="types"
          ></eventExtract>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { calendarYearList, filterTypeList } from "../assets/js/utils";
import { SchoolService } from "@/services/school.service";
import { pupilMixin } from "@/mixins/pupilMixin.js";
import { assessmentMixin } from "@/mixins/assessmentMixin.js";

const dropdown = () => import("@/components/Dropdown.vue");

const filterSection = () => import("@/components/helpers/PupilFilter.vue");
const assessmentExtract = () =>
  import("@/components/reports/TheAssessmentData.vue");
const assessmentChart = () =>
  import("@/components/charts/TheAssessmentChart.vue");
const activityChart = () => import("@/components/charts/TheActivityChart.vue");
const activityExtract = () =>
  import("@/components/reports/TheActivityData.vue");
const eventChart = () => import("@/components/charts/TheEventChart.vue");
const eventExtract = () => import("@/components/reports/TheEventData.vue");

export default {
  name: "reports",
  mixins: [assessmentMixin, pupilMixin],
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    componentLoaded() {
      return this.orderedAssessments.length > 0;
    },
    calendarDropdown() {
      var results = this.calendarList;
      this.selectedCalendar = results[0];
      return results;
    },
    orderedAssessments() {
      var vm = this;

      let allGrades = vm.assessments;
      let years = vm.filteredYears;
      let types = vm.filteredTypes;
      let classChosen = vm.filteredClass;

      return this.parseToScores(allGrades, years, types, classChosen);
    },
    activitiesFiltered() {
      var vm = this;

      if (vm.showFilter === true) {
        let years = vm.filteredYears;
        let types = vm.filteredTypes;
        let classChosen = vm.filteredClass;

        var pupils = this.filterPupils(years, types, classChosen);
        const uniquePupils = [
          ...new Set(pupils.map((item) => item.identifier)),
        ];

        return vm.activities.filter((a) => {
          if (a.registers != undefined) {
            var registerIds = [
              ...new Set(a.registers.flatMap((item) => item.identifier)),
            ];
            return uniquePupils.some((r) => registerIds.includes(r));
          }
        });
      } else {
        return vm.activities;
      }
    },
    eventsFiltered() {
      var vm = this;

      if (vm.showFilter === true) {
        let years = vm.filteredYears;
        let types = vm.filteredTypes;
        let classChosen = vm.filteredClass;

        var pupils = this.filterPupils(years, types, classChosen);
        const uniquePupils = [
          ...new Set(pupils.map((item) => item.identifier)),
        ];

        return vm.events.filter((e) => {
          if (e.registers != undefined) {
            var registerIds = [
              ...new Set(e.registers.flatMap((item) => item.identifier)),
            ];
            return uniquePupils.some((r) => registerIds.includes(r));
          }
        });
      } else {
        return vm.events;
      }
    },
  },
  data() {
    return {
      loading: true,
      errored: false,
      currentTab: 0,

      types: filterTypeList(),

      calendarList: [],
      selectedCalendar: {},

      filteredYears: [],
      filteredTypes: [],
      filteredClass: {},

      activities: [],
      events: [],

      showFilter: false,
      showSearch: false,
      search: "",
      workingAtVisible: false,
    };
  },
  created() {
    this.fetchCalendars();
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.fetchAssessment(this.selectedCalendar.value),
        this.fetchActivity(),
        this.fetchEvents();
    },
    fetchCalendars() {
      this.calendarList = calendarYearList();
      this.updateCalendarYear(this.calendarList[0]);
    },
    updateCalendarYear(cal) {
      this.selectedCalendar = cal;
      this.refresh();
    },
    fetchActivity() {
      SchoolService.getAll(
        "reporting/activities?stored=" + this.selectedCalendar.value
      )
        .then((response) => this.buildActivityList(response.data))
        .catch((err) => {
          console.log("Activities " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    buildActivityList(data) {
      this.activities = data;
    },
    fetchEvents() {
      SchoolService.getAll(
        "reporting/events?stored=" + this.selectedCalendar.value
      )
        .then((response) => this.buildEventList(response.data))
        .catch((err) => {
          console.log("Events " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    buildEventList(data) {
      this.events = data;
    },
    printReport() {
      window.print();
    },
    setFilter() {
      this.showFilter = !this.showFilter;
      if (this.showFilter === false) {
        this.clearFilter();
      }
    },
    clearFilter() {
      this.filteredClass = {};
      this.filteredYears = [];
      this.filteredTypes = [];
    },
    updateFilteredClass(value) {
      this.filteredClass = value;
    },
    updateFilteredYears(values) {
      this.filteredYears = values;
    },
    updateFilteredTypes(values) {
      this.filteredTypes = values;
    },
    checkTab(value) {
      return this.currentTab == value ? "active" : "";
    },
    showCharts() {
      this.currentTab = 0;
    },
    showAssessment() {
      this.currentTab = 1;
    },
    showEvents() {
      this.currentTab = 3;
    },
    showActivities() {
      this.currentTab = 2;
    },
    isSelectedContent(value) {
      return this.currentTab == value ? true : false;
    },
    setSearch() {
      this.showSearch = !this.showSearch;
      this.search = "";
    },
  },
  components: {
    assessmentChart,
    activityChart,
    assessmentExtract,
    activityExtract,
    eventChart,
    eventExtract,
    filterSection,
    dropdown,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
