import { SchoolService } from "@/services/school.service"
import { mapGetters } from "vuex";

export const assessmentMixin = {
  computed: {
    ...mapGetters({
      settings: "settings"
    }),
    gradelabels() {
      return this.settings.gradeList.map(g => g.value)
    },
    expectedLabels() {
      return this.expectancies.map(e => e.title)
    },
  },
  data() {
    return {
      loading: true,
      errored: false,
      assessments: [],
      expectancies: [
        {
          low: 1.68,
          high: 4.1,
          title: "Expected or Exceeding",
          color: "#FFEAAA",
          key: "expect_exceed"
        },
        {
          low: 2.34,
          high: 4.1,
          title: "Exceeding",
          color: "#A3E9D2",
          key: "exceeding"
        },
        {
          low: 1.68,
          high: 2.34,
          title: "Expected",
          color: "#A7C2FF",
          key: "expected"
        },
        {
          low: 0.0,
          high: 1.68,
          title: "Working Towards",
          color: "#FBB3B3",
          key: "working"
        }
      ],
      grades: [
        {
          low: 1.0,
          high: 1.68,
          title: "Bronze",
          color: "#E4C289",
          key: "bronze"
        },
        {
          low: 1.68,
          high: 2.34,
          title: "Silver",
          color: "#D2D0D3",
          key: "silver"
        },
        {
          low: 2.34,
          high: 3.1,
          title: "Gold",
          color: "#EEDB91",
          key: "gold"
        },
        {
          low: 3.1,
          high: 4.1,
          title: "Greater Than",
          color: "#DFDCD0",
          key: "greater"
        },
      ]
    };
  },
  methods: {
    fetchAssessment(calendar) {
      SchoolService.getAll(
        "reporting/assessments?stored=" + calendar
      )
        .then((response) => this.buildAssessmentList(response.data))
        .catch((err) => {
          console.log("Activities " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    buildAssessmentList(data) {
      this.assessments = data;
    },
    parseToScores(allGrades, years, types, classChosen) {

      let resultsFiltered = allGrades.filter(a => {
        let filtered = true;

        if (years.length > 0) {
          filtered = years.includes(a.register.yeargroup);
        }

        if (filtered && classChosen != undefined) {
          if (classChosen.id > 0) {
            filtered = a.register.class === classChosen.name;
          }
        }

        if (filtered) {
          if (types.length > 0) {

            if (types.includes("male")) {
              filtered = a.register.gender.toLowerCase() == 'male';
            } else if (types.includes("female")) {
              filtered = a.register.gender.toLowerCase() == 'female';
            } else if (types.includes("premium")) {
              filtered = a.register.premium_pupil.Int64 > 0;
            } else if (types.includes("sen")) {
              filtered = a.register.sen_status.String.toLowerCase().startsWith("sen");
            } else if (types.includes("eal")) {
              filtered = a.register.english_second.Int64 > 0;
            }
          }
        }

        return filtered;
      });

      const uniquePupils = [...new Set(resultsFiltered.map(item => item.register.identifier))]

      let data = uniquePupils.map(result => {
        let results = allGrades.filter(rr => {
          return rr.register.identifier == result;
        });

        let criteriaAList = results.map(row => {
          return row.assessment.performance;
        });

        let criteriaBList = results.map(row => {
          return row.assessment.collaboration;
        });

        let criteriaCList = results.map(row => {
          return row.assessment.competition;
        });


        let criteriaA =
          this.calculateGrade(criteriaAList) / criteriaAList.length;
        let criteriaB =
          this.calculateGrade(criteriaBList) / criteriaBList.length;
        let criteriaC =
          this.calculateGrade(criteriaCList) / criteriaCList.length;

        let displayCriteriaA = criteriaA.toFixed(1);
        let displayCriteriaB = criteriaB.toFixed(1);
        let displayCriteriaC = criteriaC.toFixed(1);

        let overall = (
          (parseFloat(criteriaA) +
            parseFloat(criteriaB) +
            parseFloat(criteriaC)) /
          3
        ).toFixed(2);

        return {
          name: results[0].register.name,
          criteriaA: displayCriteriaA,
          criteriaB: displayCriteriaB,
          criteriaC: displayCriteriaC,
          grade: overall
        };
      });
      return data;

    },

    calculateGrade(list) {
      if (list.length == 0) {
        return 0;
      }

      let result = list.reduce(function (previous, current) {
        switch (current.String.toLowerCase()) {
          case "greater":
            return previous + 4;
          case "gold":
            return previous + 3;
          case "silver":
            return previous + 2;
          case "bronze":
            return previous + 1;
          default:
            return previous;
        }
      }, 0);

      return result;
    },
  }
}